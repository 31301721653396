<template>
    <section class="table-plans">
        <div class="loading-position">
            <Loading :class="{ icon: true, reload: true, loading }" @click="() => !loading && getClinicPlanTables()"/>
        </div>
        <div v-if=clinicHealthPlans?.plans.length>
            <div v-for="(clinicPlan, i) in clinicHealthPlans.plans" :key="i">
                <div class="health-plan-settings-header">
                    <div class="title">Tabelas plano: {{ clinicPlan.plan.name }}</div>
                    <div class="header-actions">
                        <b-button 
                            variant="outline-primary"
                            @click="() => clinicHealthPlanTables[clinicHealthPlan.id].push({ table: { type: TABLE.TYPE_OTHER_EXPENSES }, sub_type: TABLE.TYPE_OTHER_EXPENSES })"
                        >
                            Adicionar tabela de outras despesas
                        </b-button>
                    </div>
                </div>
                <b-table-simple responsive fixed class="table-tables">
                    <thead>
                        <tr>
                            <th width="150px">Tipo</th>
                            <th>Tabela</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(clinicHealthPlanTable, index) in clinicHealthPlanTables[clinicPlan.id]" :key="index">
                            <td>{{ TABLE.parseTypeToLabelGrouped(clinicHealthPlanTable.sub_type) }}</td>
                            <td>
                                <div class="actions">
                                    <TableSelector
                                        v-model="clinicHealthPlanTable.table"
                                        :clinicHealthPlanTables="clinicHealthPlanTables[clinicPlan.id]"
                                        :types="getTypes(clinicHealthPlanTable.sub_type)"
                                        :clinicHealthPlan="clinicHealthPlan"
                                        :placeholder="selectorPlaceholder(clinicHealthPlanTable.sub_type)"
                                        @select="table => onSelectTable(clinicHealthPlanTable, table)"
                                        @remove="(table) => onRemoveSelection(clinicPlan.id, clinicHealthPlanTable, table)"
                                    />
                                    <Settings 
                                        v-if="
                                            [TABLE.TYPE_PROCEDURE, TABLE.TYPE_MAT_MED_SIMPRO, TABLE.TYPE_MAT_MED_BRASINDICE]
                                                .includes(clinicHealthPlanTable.table.type)
                                        "
                                        class="icon"
                                        :class="{ disabled: !clinicHealthPlanTable.id }"
                                        v-b-tooltip.hover title="Configurações"
                                        @click="clinicHealthPlanTable.id && openTableSettingsModal(clinicHealthPlanTable)"
                                    />
                                    <Delete 
                                        v-if="![TABLE.TYPE_PROCEDURE, TABLE.TYPE_MAT_MED_SIMPRO, TABLE.TYPE_MAT_MED_BRASINDICE]
                                            .includes(clinicHealthPlanTable.table.type)"
                                        class="icon"
                                        v-b-tooltip.hover title="Remover tabela"
                                        @click="onRemoveTable(clinicPlan.id, clinicHealthPlanTable, index)"
                                    />
                                    <a :href="`/tables/d/${clinicHealthPlanTable.table.id}`" v-if="clinicHealthPlanTable.table?.id">
                                        <List 
                                        class="icon"  
                                        v-b-tooltip.hover title="Ver itens da tabela"
                                        />
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </b-table-simple>
            </div>

        </div>

        <div v-else>
            <div class="health-plan-settings-header-no-content">
                <div class="title">Nenhum plano de saúde vinculado</div>
            </div>
        </div>

        <TableProcedureSettingsModal 
            :clinicHealthPlanTable="clinicHealthPlanTable"
            @updated="updatedProcedureSettings"
            @hidden="clinicHealthPlanTable = null"
        />
    </section>
</template>
<script>
import api from '@tables/api';
import * as TABLE from '@tables/utils/constants';

export default {
    components: {
        Loading: () => import('@/assets/icons/loading.svg'),
        TableSelector: () => import('@tables/components/TableSelector'),
        Settings: () => import('@tables/assets/icons/setting-tune.svg'),
        Delete: () => import('@/assets/icons/delete.svg'),
        TableProcedureSettingsModal: () => import('@tables/modals/TableProcedureSettingsModal'),
        List: () => import('@tables/assets/icons/list.svg')
    },
    props: {
        clinicHealthPlan: Object,
        clinicHealthPlans: Object,
    },
    data() {
        return {
            TABLE,
            loading: false,
            clinicHealthPlanId: this.$route.params.id,
            clinicHealthPlanTables: [],
            clinicHealthPlanTable: null,
        }
    },
    async mounted() {
        await this.getClinicPlanTables()
    },
    methods: {
        async setClinicHealthPlanTableObject() {
            this.clinicHealthPlanTables = this.clinicHealthPlans.plans.reduce((acc, clinicPlan) => {
                acc[clinicPlan.id] = this.getDefaultClinicHealthPlanTables(clinicPlan.id)
                return acc
            }, {})

        },
        getDefaultClinicHealthPlanTables(clinicPlanId) {
            return [
                { clinic_plan_id: clinicPlanId, table: { type: TABLE.TYPE_PROCEDURE }, sub_type: TABLE.TYPE_PROCEDURE },
                { clinic_plan_id: clinicPlanId, table: { type: TABLE.TYPE_MAT_MED_SIMPRO }, sub_type: TABLE.TYPE_MATERIAL },
                { clinic_plan_id: clinicPlanId, table: { type: TABLE.TYPE_MAT_MED_SIMPRO }, sub_type: TABLE.TYPE_MEDICINE},
                { clinic_plan_id: clinicPlanId, table: { type: TABLE.TYPE_OTHER_EXPENSES }, sub_type: TABLE.TYPE_OTHER_EXPENSES },
            ]
        },
        getClinicHealthPlanTables() {
            return  Object.values(this.clinicHealthPlanTables);
        },
        selectorPlaceholder(subType) {
            const types = {
                [TABLE.TYPE_PROCEDURE]: 'Selecione a tabela de procedimentos',
                [TABLE.TYPE_MATERIAL]: 'Selecione a tabela de materiais',
                [TABLE.TYPE_MEDICINE]: 'Selecione a tabela de medicamentos',
                [TABLE.TYPE_OTHER_EXPENSES]: 'Selecione a tabela de outras despesas',
            }
            return types[subType] || 'Selecione uma tabela'
        },
        openTableSettingsModal(clinicHealthPlanTable) {
            this.clinicHealthPlanTable = clinicHealthPlanTable
            this.$bvModal.show('table-procedure-settings-modal')
        },
        getTypes(subType) {
            const types = {
                [TABLE.TYPE_PROCEDURE]: [TABLE.TYPE_PROCEDURE],
                [TABLE.TYPE_MATERIAL]: [TABLE.TYPE_MAT_MED_SIMPRO, TABLE.TYPE_MAT_MED_BRASINDICE],
                [TABLE.TYPE_MEDICINE]: [TABLE.TYPE_MAT_MED_SIMPRO, TABLE.TYPE_MAT_MED_BRASINDICE],
                [TABLE.TYPE_OTHER_EXPENSES]: [TABLE.TYPE_OTHER_EXPENSES],
            }
            return types[subType] || []
        },
        async getClinicPlanTables() {
            this.loading = true
            try {
                await this.setClinicHealthPlanTableObject()
                const { data } = await api.getClinicPlanTables(this.clinicHealthPlanId)
                data.map(clinicHealthPlanTable => {
                    const key = clinicHealthPlanTable.clinic_plan_id
                    if (!key) return
                    const index = this.clinicHealthPlanTables[key].findIndex(el => !el.table?.id && el.sub_type === clinicHealthPlanTable.sub_type)
                    if (index >= 0) this.clinicHealthPlanTables[key][index] = clinicHealthPlanTable
                    else this.clinicHealthPlanTables[key].push(clinicHealthPlanTable)
                })
            } catch(err) {
                console.error(err)
                this.$toast.error(err.message)
            } finally {
                this.loading = false
            }
        },
        onRemoveSelection(key, clinicHealthPlanTable, table) {
            const index = this.clinicHealthPlanTables[key].findIndex(el => el.id === clinicHealthPlanTable.id)
            clinicHealthPlanTable.id && this.removeTable(key, null, index, clinicHealthPlanTable.id)
            this.clinicHealthPlanTables[key][index] = { table: { type: table.type }, sub_type: clinicHealthPlanTable.sub_type }
        },
        async onSelectTable(clinicHealthPlanTable, table) {
            const payload = {
                id: clinicHealthPlanTable.id,
                clinic_health_plan_id: this.clinicHealthPlanId,
                clinic_plan_id: clinicHealthPlanTable.clinic_plan_id,
                table_id: table.id,
                sub_type: clinicHealthPlanTable.sub_type,
            }
            await this.save(payload)
            await this.getClinicPlanTables()
        },
        async save(payload) {
            this.loading = true
            try {
                !payload.id ? await api.createClinicHealthPlanTable(payload) : await api.updateClinicHealthPlanTable(payload.id, payload)
                this.$toast.success('Tabela vinculada com sucesso')
            } catch(err) {
                this.$toast.error(err.message)
            } finally {
                this.loading = false
            }
        },
        removeTable(key, clinicHealthPlanTable, index, id) {
            this.loading = true
            api.deleteClinicHealthPlanTable(id)
                .then(() => {
                    index && clinicHealthPlanTable[key].splice(index, 1)
                    this.$toast.success('Tabela removida com sucesso')
                })
                .catch(err => this.$toast.error(err.message))
                .finally(() => (this.loading = false))
        },
        onRemoveTable(key, clinicHealthPlanTable, index) {
            if (!clinicHealthPlanTable?.table?.id) {
                this.clinicHealthPlanTables[key].splice(index, 1)
                return
            }
            this.$swal({
                icon: 'question',
                html: `<div class="body-alert">Deseja realmente remover a tabela <span>${clinicHealthPlanTable.table.name}</span>?</div>`,
                showCancelButton: true,
                confirmButtonColor: '#F63220',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                reverseButtons: true
            }).then(res => {
                if (res.isConfirmed) {
                    if (clinicHealthPlanTable.id) {
                        this.removeTable(key, clinicHealthPlanTable, index, clinicHealthPlanTable.id)
                    }
                }
            })
        },
        updatedProcedureSettings(props) {
            const clinicHealthPlanTables = { ...this.clinicHealthPlanTables }
            Object.keys(clinicHealthPlanTables).map(key => {
                const index = clinicHealthPlanTables[key].findIndex(({ id }) => id === props.id)
                clinicHealthPlanTables[key].splice(index, 1, props)
            })
            this.clinicHealthPlanTables = clinicHealthPlanTables
        }
    },
    watch: {
        async clinicHealthPlans() {
            await this.setClinicHealthPlanTableObject()
            await this.getClinicPlanTables()
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
.table-plans {
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;
    height: 80vh;
}
.health-plan-settings-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
    .title {
        font-family: 'Red Hat Display';
        font-weight: 700;
        font-size: 1.2rem;
        color: var(--blue-500);
    }
    .header-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
    }
}
.health-plan-settings-header-no-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    .title {
        font-family: 'Red Hat Display';
        font-weight: 700;
        font-size: 1.2rem;
        color: var(--blue-500);
    }

}
.table-tables {
    overflow-x: unset !important;
}
.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    .icon {
        width: 24px;
        height: 24px;
        stroke: var(--blue-500);
        cursor: pointer;

        &.disabled {
            stroke: var(--neutral-500);
            cursor: not-allowed;
        }
    }
}
.add-table-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.table-type {
    color: var(--neutral-500);
}
.loading-position {
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
}
</style>